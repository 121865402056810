import React from 'react';
import { createPortal } from 'react-dom';
import Backdrop from './backdrop'
import '../styles/modal_frame.css'

class ModalFrame extends React.Component {

  componentWillUnmount() {
    if (this._modal) {
      try {
        this._modal.parentElement.removeChild(this._modal);
      } catch (e) {}
    }
  }

  handleClick = () => {
    return null
  }

  get modal() {
    if (!this._modal) {
      this._modal = document.createElement('div');
      document.body.appendChild(this._modal);
    }
    return this._modal;
  }

  render() {
    const { open, ...props } = this.props

    if (!open) {
      return null
    }
    return createPortal((
      <div className="modal-frame-centerer">
        <Backdrop onClose={props.onClose} />
        {props.children}
      </div>
    ), this.modal)
  }
}

export default ModalFrame;
import React, { Component } from 'react'
import '../styles/header.css';

export default class Header extends Component {
  render() {
    return (
      <div id="header">
        <h1>Ima Write A Letter</h1>
        <span className="header-span">Letters of joy, dismay... and responses</span>
        <br />
        <span className="preface">Preface: I believe that as a consumer I should let companies know when I'm upset with them, and equally so, when I'm elated with them. I also love coupons.</span>
      </div>
    )
  }
}
import React, { Component, Fragment} from 'react'
import Parser from 'html-react-parser'
import "../styles/letter.css"

export default class Letter extends Component {

  render (){
    const {letter} = this.props
    return (
      <Fragment>
        <div className="letter">
          <div className="letter-title" ref="top">
            <h2>{letter.title}<small>{letter.date}</small></h2>
          </div>
          <div className="letter-body">
            {Parser(letter.letter_html)}
          </div>
          <div className={`result-header result-header-${letter.result}`}>
            <h2>Result: {letter.result_text}</h2>
          </div>
          <div className="response-content">
            { this.display_image_if_present(letter.result_image) }
            <div className="response-html">
              {Parser(letter.response_html)}
            </div>
          </div>
        </div>
      </Fragment>
    )
  }

  display_image_if_present = (result_image) => {
    return result_image !== null ?
      <img 
        onClick={this.props.onImageClick}
        className="response-image" src={"images/" + result_image} 
        alt={result_image} />
    :
      <div></div>
  }
}
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../styles/navbar.css'
import snakeCase from '../helpers/snakeCase'

export default class NavBar extends Component {
  render() {
    return (
      <div id="navbar">
        <h3 className="jump-header">Jump to Letter</h3>
        {this.props.letters.map(
          letter =>
            <Link to={`/${snakeCase(letter.title)}`} key={letter.id} className="nav-button-a">
              <button className="nav-button">
                {letter.title}
              </button>
            </Link>
        )}
      </div>
    );
  };
}
import React, { Component, Fragment } from 'react'
import '../styles/tall_image_modal.css'
import PropTypes from 'prop-types'

export default class TallImageModal extends Component {

  static propTypes = {
    onClose: PropTypes.func,
    image: PropTypes.string
  }

  render() {
    const { onClose, image } = this.props
    return(
      <Fragment>  
        <div className="tall_image_modal">
          <button className="image-modal-close" onClick={onClose}>X</button>
          <img className="modal-image" src={"images/" + image} alt={image} />
        </div>
      </Fragment>
    )
  }

}
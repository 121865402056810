import React, { Component, Fragment } from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import NavBar from './navbar'
import Letter from './letter'
import { LETTERS } from '../letters'
import ModalFrame from './modal_frame'
import TallImageModal from './tall_image_modal'
import snakeCase from '../helpers/snakeCase'
import "../styles/content.css"

export default class Content extends Component {
  state = {
    show_image_modal: false,
    current_image: null
  }

  handleCloseModal = () => {
    const show_image_modal = false
    this.setState({ show_image_modal })
  }

  handleOpenImageModal = () => { 
    const show_image_modal = true
    this.setState({ show_image_modal })
  }

  setImage = (image) => { 
    if (this.state.current_image !== image) {
      const current_image = image
      this.setState({ current_image })
    }
    return null
  }

  render () {
    return (
      <Fragment>
        <ModalFrame open={this.state.show_image_modal} onClose={this.handleCloseModal}>
          <TallImageModal image={this.state.current_image} onClose={this.handleCloseModal} />
        </ModalFrame>
        <div className="content-container">
          <NavBar letters={LETTERS} />
          <Switch>
            <Route path={`/:letterTitle`}>
              <SetLetter 
                letters={ LETTERS } 
                onImageClick={this.handleOpenImageModal}
                setImage={this.setImage}
              />
            </Route>
            <Route path="/">
              <SetLetter 
                letters={ LETTERS } 
                onImageClick={this.handleOpenImageModal}
                setImage={this.setImage}
              /> 
            </Route>
          </Switch>
        </div>
      </Fragment>
    )
  }
}

function SetLetter(props) {
  window.scroll({top: 0, left: 0, behavior: 'smooth',})
  const { letters, onImageClick, setImage} = props
  const { letterTitle } = useParams();
  let letter = letters.find(function(letter) { return snakeCase(letter.title) === snakeCase(letterTitle) })
  if (letter === undefined) {
    letter = letters[0]
  }
  setImage(letter.result_image)
  return <Letter 
    letter={letter} 
    onImageClick={onImageClick} 
  />
};
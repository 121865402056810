import React from 'react'
import { func } from 'prop-types'

import '../styles/backdrop.css'

const backdrop = props => <div onClick={ props.onClose } className="backdrop"></div>

backdrop.propTypes = {
  onClose: func
};

export default backdrop